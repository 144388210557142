
  import React from 'react';
  import COMPONENTS from '../../components';
  import { get } from 'lodash';
  import PageLayout from '../../layout';
  import { graphql } from 'gatsby';

  class Page extends React.Component {
    state = {
      data: [],
    };

    componentDidMount() {
      let slices;

      const data = get(this, 'props.data.portfolioConcrJson');

      if (data) {
        slices = Object.entries(data).map(([key, content]) => ({
          componentName: key,
          ...content,
        }));

        slices.sort(
          ({ position: positionA }, { position: positionB }) =>
            positionA - positionB
        );

        this.setState({
          data: slices,
        });
      }
    }

    render() {
      return (
        <PageLayout>
          {get(this, 'state.data').map(({ componentName, data }, index) => {
            const ComponentType = COMPONENTS[componentName];
            return <ComponentType key={index} {...data} />;
          })}
        </PageLayout>
      );
    }
  }

  export default Page;

  export const pageQuery = graphql`
    query {
      portfolioConcrJson {
        
      
      
      
      
      
      
      
      CTAWith2Buttons0 {
        position
        componentName
        data {
           background title description buttonDark {  text link } buttonLight {  text link }
        }
      }
    
      ProductSingle1 {
        position
        componentName
        data {
           title textBody image
        }
      }
    
      SlideshowProfile2 {
        position
        componentName
        data {
           slides {  image title textBody }
        }
      }
    
      Divider3 {
        position
        componentName
        data {
           height
        }
      }
    
      Header4 {
        position
        componentName
        data {
           background title type subtitle showRedLine
        }
      }
    
      Carousel5 {
        position
        componentName
        data {
           images
        }
      }
    
      OpenPositions6 {
        position
        componentName
        data {
           alwaysShowAdditionalLink callToAction {  title textBody button {  text link } } companyId htmlElementId maxRows additionalLink {  text link }
        }
      }
    
      }
    }
  `